
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {
  computed, defineComponent,
} from 'vue'
import Page from '@/components/Page.vue'
import Box from '@/components/Box.vue'
import UpdateUserForm from '@/components/Forms/UpdateUserForm.vue'
import Stat from '@/components/Stat.vue'
import { TUser, IUserPro } from '@/types'
import PageTop from '@/components/PageTop.vue'
import { isHubUser, isProUser } from '@/globals/javascript/utils/typeGuards'

export default defineComponent({
  components: {
    Page,
    Box,
    UpdateUserForm,
    Stat,
    PageTop,
  },
  setup() {
    const store = useStore()

    const route = useRoute()
    const user = computed<TUser | undefined>(
      () => route.params.userID && store.getters.getUserByID(route.params.userID),
    )
    return {
      user,
      isHubUser: isHubUser(user.value),
      isProUser: isProUser(user.value),
      isBothUsers: isHubUser(user.value) && isProUser(user.value),
    }
  },
})

